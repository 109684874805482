<p class="preferences_title">Preferences</p>
<div class="preferences" *ngIf="isEditing; else card">
  <div class="preferences_items flex flex-column">
    <div [formGroup]="preferencesForm" class="preferences_item">
      <p class="preferences_item__heading">Name</p>
      <stxt-input formControlName="name" [inputConfig]="nameInput"></stxt-input>
    </div>
    <div class="preferences_item">
      <p class="preferences_item__heading">How do you identify?</p>
      <div class="preferences_item__options flex">
        <stxt-profile-option
          *ngFor="let option of identifyOptions"
          (click)="selectGender(option)"
          (keydown.enter)="selectGender(option)"
          [option]="option"
          [disabled]="buttonConfig.isLoading"
        ></stxt-profile-option>
      </div>
    </div>
    <div class="preferences_item">
      <p class="preferences_item__heading">Who are you interested in?</p>
      <div class="preferences_item__options flex">
        <stxt-profile-option
          *ngFor="let option of interestsOptions"
          (click)="toggleInterest(option)"
          (keydown.enter)="toggleInterest(option)"
          [option]="option"
          [disabled]="buttonConfig.isLoading"
        ></stxt-profile-option>
      </div>
    </div>
    <div class="preferences_item w-fit-content">
      <stxt-button
        [disabled]="buttonConfig.isLoading"
        [buttonConfig]="buttonConfig"
        (click)="saveProfilePreferences()"
        (keydown.enter)="saveProfilePreferences()"
        class="preferences_button"
      >
        {{ buttonConfig.text }}
      </stxt-button>
    </div>
  </div>
</div>
<ng-template #card>
  <div class="preferences_card">
    <div class="preferences_card_header">
      <p class="preferences_card_header__title">
        Welcome,
        <span>{{ name }}</span>
      </p>
      <button (click)="isEditing = true" (keydown.enter)="isEditing = true" class="btn-nostyle">
        <stxt-svg-icon [iconConfig]="editIcon"></stxt-svg-icon>
      </button>
    </div>
    <div class="preferences_card_body">
      <p class="preferences_card_body__identify">
        I identify as
        <span>{{ selectedGender }}</span>
      </p>
      <p class="preferences_card_body__interests">
        I am interested in
        <span>{{ getInterestLabels() }}</span>
      </p>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="isToastMessage">
  <stxt-success-toast
    [toastHeading]="toast.toastHeading"
    [toastSubheading]="toast.toastSubheading"
    (closeToast)="isToastMessage = false"
    class="success-toast"
  ></stxt-success-toast>
</ng-container>
